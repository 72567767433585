import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Button from './Button'


export const StyledRouterLink = styled(Link)`
    text-decoration: none;
    color: #FFFFFF;
`;

interface Props {
    to: string,
    onClickProp?: any,
    disabled?: boolean,
    loading?: boolean,
    secondary?: boolean,
    className?: string,
    children?: any,
}

const StyledOutboundLinkButton = ({to, onClickProp, disabled = false, loading = false, secondary, className, children}: Props) => {
    return (
        <StyledRouterLink to={to}>
            <Button onClickProp={onClickProp} className={className} disabled={disabled} loading={loading} secondary={secondary} >
                { children }
            </Button>
        </StyledRouterLink>
    )
}

export default StyledOutboundLinkButton;