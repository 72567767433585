export function parseError(action) {
    let error = '';
    
    //action.error should only exist if the response code is non 2xx or there was no response
    //https://www.npmjs.com/package/axios#handling-errors
    if (action && action.error) {
        //action?.error?.response?.data?.exception?.exceptionMessage || action?.error?.response?.data?.exception || action?.error?.response?.data?.error || action?.error?.response?.data || action?.errror?.message || "Request failed"
        try {
            console.error("Request Failed - %s", action.error);
        } catch(e) {}

        if (action.error.response && action.error.response.data) {
            if (action.error.response.data.exception && action.error.response.data.exception.exceptionMessage) {
                error = action.error.response.data.exception.exceptionMessage
            }
            else if (action.error.response.data.exception) {
                error = action.error.response.data.exception
            }
            else if (action.error.response.data.error) {
                error = action.error.response.data.error
            }
            else {
                error = action.error.response.data || "Request failed"
            }
        } 
        //the request was made but no response was received
        //error.request is just the XMLHttpRequest request object
        //else if (action.error.request) {}
        else {
            error = action.error.message || "Request failed"
        }
    }
    
    //action.payload.data should always exist unless there was an error
    //https://www.npmjs.com/package/redux-axios-middleware
    else if (!(action && action.payload && action.payload.data)) {
        error = "Request failed"
    }
    
    //check for exceptions within a success response
    //exception: {exceptionLocation: "CR", exceptionCode: 999, exceptionMessage: "Unknown error"}
    else if (action.payload.data.exception && action.payload.data.exception.exceptionMessage) {
        error = action.payload.data.exception.exceptionMessage;
    }
    else if (action.payload.data.exception) {
        error = action.payload.data.exception;
    }

    //I don't know if this path is actually used by anything but I saw it in updateEmailSuccess
    else if (action.payload.exception) {
        error = action.payload.exception
    }

    //Return no error for any falsy values (e.g. [], {}, 0)
    if(!error) {
        error = '';
    }

    //Make sure we're always returning a string
    if(typeof error !== 'string') {
        error = "Request failed";
    }

    return error
}

export default parseError