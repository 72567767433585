import * as ACTION_TYPES from '../actionTypes';


export interface AirfareRedeemForm {
    dollarAmount: number,
    milesAmount: number,
    transactionReferenceNumber: string,
    // termsAndConditions: boolean, //We don't really need to save this. We just need the field to be required to submit the form.
}

export const initialState: AirfareRedeemForm = {
    dollarAmount: 0,
    milesAmount: 0,
    transactionReferenceNumber: '',
}

export const airfareRedeemForm = (state: AirfareRedeemForm = initialState, action: any) => {
    switch(action.type) {
        case ACTION_TYPES.SAVE_AIRFARE_REDEEM_FORM_DATA: {
            const dollarAmount = Number(action.payload.dollarAmount); // Cannot use parseInt() since it will remove decimal place
            const milesAmount = parseInt(action.payload.milesAmount);
            
            return {
                dollarAmount: dollarAmount,
                milesAmount: milesAmount,
                transactionReferenceNumber: action.payload.transactionReferenceNumber,
            }
        }
        case ACTION_TYPES.CLEAR_AIRFARE_REDEEM_FORM_DATA: {
            return initialState;
        }
        default: {
            return state;
        }
    }    
}

export default airfareRedeemForm;