import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

const Content = styled.div`
    max-width: ${props => props.theme.minWidth.giga};
    margin: 0 auto;
    padding: 30px 20px 40px 20px;
`

export const Title = styled.h1`
    font-weight: ${props => props.theme.font.weight.semibold};
    font-size: 40px;
    margin-bottom: 10px;
`

export const BodyP = styled.p`
    margin-bottom: 10px;
    color: rgb(0, 0, 0);
`

export const SubTitle = styled.h2`
    font-weight: ${props => props.theme.font.weight.semibold};
    font-size: 24px;
    color: rgb(0, 0, 0);
    margin: 30px 0px 20px 0px;
`


const Terms = () => {

    const { pathname } = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname]);

    return (
        <Content>
            <Title>MileagePlus Choices&reg; Terms and Conditions</Title>

            <SubTitle>MileagePlus Choices&reg; Terms and Conditions</SubTitle>
            <BodyP><b>Important:</b> Your participation in MileagePlus Choices&reg; ("Choices") is governed by (i) the following terms and conditions and (ii) the MileagePlus&reg; Program Rules found on united.com and expressly incorporated herein by reference (collectively the "Choices Terms and Conditions"). It is your responsibility to read and understand all of them. The Choices Terms and Conditions cannot be superseded or changed, except in writing from United Airlines, or any of its affiliates, (collectively, "United").</BodyP>

            <SubTitle>General conditions</SubTitle>
            <BodyP>Choices is offered at the discretion of United and United has the right to terminate Choices, in whole or in part, or to change the Choices Terms and Conditions, benefits and conditions of participation, in whole or in part, at any time, with or without notice, even though changes may affect the value of benefits already accumulated.</BodyP>
            <BodyP>"You" and "your" means the primary Cardmember on the MileagePlus credit card account and whose MileagePlus account number is associated with the account ("Cardmember"). "MileagePlus credit card" includes United branded credit cards issued by Chase Bank USA, N.A. ("Chase") that accrue MileagePlus award miles.</BodyP>
            <BodyP>Award miles accrued with your MileagePlus credit card and transferred to your MileagePlus account based on eligible card activity, including, but not limited to, credit card purchases, Chase promotional offers, and anniversary renewals, are available for use in Choices. Award miles earned (i) for flight activity, (ii) through MileagePlus partners, (iii) for Chase non-credit card activity including, but not limited to, debit/check card purchases or home equity advances, and (iv) for activity that is rewarded by third parties as bonuses associated with use of the MileagePlus credit card will be credited to your MileagePlus award miles balance but are not eligible for use in Choices. Your Choices balance is a subset of your MileagePlus award miles balance and not a separate balance. Your Choices balance can be found by visiting www.united.com/choices. You must have previously earned award miles with your MileagePlus credit card and be a current Cardmember to be able to login. Also, if you close your MileagePlus credit card account, you will forfeit your Choices balance.</BodyP>
            <BodyP>Award miles accrued with your MileagePlus credit card will continue to be reflected in your award miles balance. In addition to the options available to MileagePlus members, Choices offers more ways to use miles that are exclusive to Cardmembers. Any Choices activity using award miles will result in a reduction of your MileagePlus award miles balance. You cannot have a greater Choices balance than your MileagePlus award miles balance.</BodyP>
            <BodyP>The MileagePlus account number used for both Choices and the credit card account where the MileagePlus award miles were earned must be the same. You must be the primary Cardmember to use Choices. You cannot use your award miles through Choices for a statement credit on another person's MileagePlus credit card account.</BodyP>

            <SubTitle>Choices Airfare Statement Credit</SubTitle>
            <BodyP>To use Choices for an airfare statement credit, purchase any United ticket on united.com with your MileagePlus credit card. Flights purchased through any other source, such as United General Reservations, United Vacations, travel agencies or other travel websites, are not eligible. When the purchase posts to your credit card account, login at www.united.com/chase to use award miles earned with your MileagePlus credit card for an airfare statement credit against that charge.</BodyP>
            <BodyP>No blackouts means any flight available on united.com can be purchased for Choices any day of the year. No inventory restriction indicates that any open seat on any available flight on united.com is eligible for Choices. Award miles earned with your MileagePlus credit card can be used against a ticket purchased on united.com through Choices for an airfare statement credit up to the full cost of the ticket.</BodyP>
            <BodyP>Airfare statement credit requested may not exceed the ticket purchase amount charged to your MileagePlus credit card. Partial statement credits are allowed with a minimum of 5,000 award miles. However, only one statement credit request can be made per unique transaction.</BodyP>
            <BodyP>You must submit your request within 90 days of the ticket purchase date.</BodyP>
            <BodyP>Award miles are deducted at time of request. Please allow 5-7 days for the credit to appear on your credit card account. Since award miles earned with your MileagePlus credit card are reflected in your MileagePlus account balance, any activity through Choices will result in a reduction in your MileagePlus account balance. Use of award miles through Choices for an airfare statement credit made on or near the end of your monthly billing cycle may take up to one billing cycle to appear on your monthly billing statement. You are responsible for making your monthly credit card payment according to the terms and conditions of your account to avoid any late fees or finance charges.</BodyP>
            <BodyP>For rejected requests, award miles initially deducted will be reversed.</BodyP>
            <BodyP>E-mail confirmation will be sent to you to indicate a successful redemption and statement credit posting to your credit card account.</BodyP>
            <BodyP>The MileagePlus account number used for both Choices and the credit card account where the MileagePlus award miles were earned must be the same. You must be the primary MileagePlus Cardmember to use Choices. You cannot use your award miles through Choices for an airfare statement credit on another person's MileagePlus credit card account.</BodyP>
            <BodyP>Airline ticket changes, cancellations and refunds are subject to the rules of the airline ticket.</BodyP>
            <BodyP>Award miles used in Choices cannot be returned or re-deposited by you.</BodyP>
            <BodyP>MileagePlus and Chase reserve the right to reverse Choices air statement credits where the original ticket purchase is refunded. Award miles used will be re-deposited in your account and the Choices airfare statement credit will be reversed on your MileagePlus credit card account. In the event a reversal is required, an administrative fee of 2,500 award miles may be deducted from your MileagePlus account and will appear on your MileagePlus statement as an adjustment.</BodyP>
            <BodyP>The number of award miles required depends upon the cost of the airline ticket including all taxes and fees and the amount of the airfare statement credit requested. You are responsible for all associated taxes, airport security charges, baggage fees, fuel surcharges and other usage fees required for travel. These charges will be included in the overall calculation to determine the number of award miles required for the airfare statement credit. The number of award miles required is subject to change at any time without notice.</BodyP>

            <SubTitle>Annual Membership Fee Reimbursement</SubTitle>
            <BodyP>Use MileagePlus&reg; miles with Pay Yourself Back&reg;: Miles may be redeemed for a statement credit using Pay Yourself Back against the prior payment of your annual fee within 90 days before the redemption request date. Redemption values will be shown on the transaction page prior to redemption. Redemption values may be changed at any time. Statement credits will post to the card account within 3 business days of a request to redeem and will appear on the monthly Chase credit card billing statement within 1-2 billing cycles. Statement credits will reduce your balance but you are still required to make at least your minimum monthly payment. Chase reserves the right to determine which purchases qualify for a statement credit.</BodyP>

            <br/>
            <br/>
            <BodyP>Copyright &copy; {moment().format('YYYY')} United Airlines, Inc.</BodyP>
            <BodyP>All rights reserved.</BodyP>
        </Content>
    )
}

export default Terms;