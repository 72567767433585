import { v4 as uuid } from 'uuid';
import * as ACTION_TYPES from './actionTypes';
import { Profile } from './reducers/profile';
import { AirfareRedeemForm } from './reducers/airfareRedeemForm';

//Random 7 digit int as string (crew-chase)
//guid? How long?
const generateTransactionId = (): string => {
    return uuid();
}

export const getAuthCode = (location: any) => ({
    type: ACTION_TYPES.GET_AUTH_CODE,
    location,
})

export const logout = () => ({
    type: ACTION_TYPES.LOGOUT,
})

export const login = () => ({
    type: ACTION_TYPES.LOGIN,
})

export const getProfile = (onlineAuthCode: string) => ({
    type: ACTION_TYPES.GET_PROFILE,
    payload: {
        request: {
            method: 'GET',
            url: process.env.REACT_APP_CHOICES_URL as string + '/profile',
            params: {
                SID: process.env.REACT_APP_SID,
                transactionID: generateTransactionId(),
            },
            headers: {
                Authorization: onlineAuthCode,
            }
        }
    }
})

export const saveAirfareRedeemFormData = (formData: AirfareRedeemForm, milesAmount: number) => {
    return ({
        type: ACTION_TYPES.SAVE_AIRFARE_REDEEM_FORM_DATA,
        payload: {...formData, milesAmount},
    })
}

export const clearAirfareRedeemFormData = () => ({
    type: ACTION_TYPES.CLEAR_AIRFARE_REDEEM_FORM_DATA,
})

export const stopReviewing = () => {
    return ({
        type: ACTION_TYPES.STOP_REVIEWING,
    })
}

export const airfareRedeem = (onlineAuthCode: string, formData: AirfareRedeemForm, profile: Profile) => ({
    type: ACTION_TYPES.AIRFARE_REDEEM,
    payload: {
        request: {
            method: 'POST',
            url: process.env.REACT_APP_CHOICES_URL as string + '/airfare/redeem',
            params: {
                SID: process.env.REACT_APP_SID,
            },
            headers: {
                Authorization: onlineAuthCode,
            },
            data: {
                transactionID: generateTransactionId(),
                centAmount: Math.round(formData?.dollarAmount * 100),
                miles: formData?.milesAmount,
                email: profile.email,
                transactionRefNumber: formData.transactionReferenceNumber,
            }
        }
    }
})

export const getTransactions = (onlineAuthCode: string) => ({
    type: ACTION_TYPES.GET_TRANSACTIONS,
    payload: {
        request: {
            method: 'GET',
            url: process.env.REACT_APP_CHOICES_URL as string + '/transactions',
            params: {
                SID: process.env.REACT_APP_SID,
            },
            headers: {
                Authorization: onlineAuthCode,
            },
            data: {},
        }
    }
})