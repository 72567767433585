export const NULL_ACTION = 'NULL_ACTION';
export const GET_AUTH_CODE = 'GET_AUTH_CODE';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';
export const STOP_REVIEWING = 'STOP_REVIEWING';
export const SAVE_AIRFARE_REDEEM_FORM_DATA = 'SAVE_AIRFARE_REDEEM_FORM_DATA';
export const CLEAR_AIRFARE_REDEEM_FORM_DATA = 'CLEAR_AIRFARE_REDEEM_FORM_DATA';
export const AIRFARE_REDEEM = 'AIRFARE_REDEEM';
export const AIRFARE_REDEEM_SUCCESS = 'AIRFARE_REDEEM_SUCCESS';
export const AIRFARE_REDEEM_FAIL = 'AIRFARE_REDEEM_FAIL';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAIL = 'GET_TRANSACTIONS_FAIL';

export const nullAction = {
    type: NULL_ACTION,
}

//Action outputs
export interface AxiosRequestPayload {
    request: {
        method: 'GET' | 'POST',
        url: string,
        params: {
            transactionId: string,
            [prop: string]: any,
        }
        data: any
    }
}

export interface AxiosResponseAction {
    type: string;
    payload: {
        data: {
            [props: string]: any;
        }
    }
}

export interface GetProfileAction {
    type: 'GET_PROFILE' | 'GET_PROFILE_SUCCESS' | 'GET_PROFILE_FAIL',
    //payload: axios middleware payload
    payload: AxiosRequestPayload
}