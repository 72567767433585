import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import 'core-js/es/map';
import 'core-js/es/set';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppWrappers from './AppWrappers';
import * as serviceWorker from './serviceWorker';
import './fonts/OpenSans-Regular.ttf'

ReactDOM.render(
  <React.StrictMode>
    <AppWrappers />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
