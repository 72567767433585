import { createStore, compose, applyMiddleware } from 'redux';
import combinedReducers from './reducers/rootReducer';
import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';
import { createLogger } from 'redux-logger';


const composeEnhancers = compose; //(process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const logger = createLogger();

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_CREW_SERVICES_URL,
    responseType: 'json',
});

const middleware = [axiosMiddleware(axiosClient), logger];

const store = createStore(
    combinedReducers,
    composeEnhancers(applyMiddleware(...middleware)),
);

export default store;