import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Routes from './Routes';

import { login, logout } from './redux/actions';
import styled from 'styled-components';
import mileageplus_logo from './images/logo_mileageplus_reverse.png';
import profile_icon from './images/Header-with-MP-info.svg';

import { RootState } from './redux/reducers/rootReducer';
import { Profile } from './redux/reducers/profile';
import { Dispatch } from 'redux';

const StyledHeader = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    height: 88px;
    margin: 0px 0 0 0;
    background: #0D243F 0% 0% no-repeat padding-box;    
    color: white;
    z-index: 9999;

    @media (min-width: ${props => props.theme.minWidth.giga}) {
        height: 88px;
    }
`

export const StyledHeaderLogoLink = styled(Link)`
    display: inline-block;
    height: 100%;
`

export const MileagePlusLogo = styled.img`
    width: 120px;
    height: 38.57px;
    margin-left: 20px;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    @media (min-width: ${props => props.theme.minWidth.giga}) {
        width: 140px;
        height: 45px;
        margin-left: 39px;
    }
`

export const ProfileIcon = styled.img`
    width: 14.5px;
    height: 18px;
    margin-left: 68px;

    @media (min-width: ${props => props.theme.minWidth.giga}) {
        margin-right: 8.5px;
        margin-left: 0px;
    }
`

const NavBar = styled.div`
    float: right;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0px 21px 0px 0px;
    width: 104px;

    @media (min-width: ${props => props.theme.minWidth.giga}) {
        padding: 0px 40px 0px 0px;
        width: unset;
    }
`

const NavItem = styled.div`
    display: none;

    @media (min-width: ${props => props.theme.minWidth.giga}) {
        display: block;
        color: #ffffff;
        text-decoration: none;
        padding: 0px 22.5px 0px 0px;
        display:inline-block;
    }
`

export const StyledButton = styled.button`
    border: none;
    background: #0D243F 0% 0% no-repeat padding-box;
    font-family: "Open Sans", sans-serif;
    font-size: 16px; 
    font-style: normal;
    color: #ffffff;
    cursor: pointer;
    display: block;
    padding: 21px 0px 21px 0px;
    width: 104px;
    margin-top: 0px;
    text-align: center;

    @media (min-width: ${props => props.theme.minWidth.giga}) {
        display:inline-block;
        padding: 0px 0px 0px 14.5px;
        text-align: none;
        border-left: solid;
        border-color: #ffffff;
        border-width: 1px;
        border-radius: 0;
    }
`

export const Header = (props: any) => {
    const profile: Profile = useSelector((state: RootState) => state.profile);
    const dispatch: Dispatch = useDispatch();

    return (
        <StyledHeader id="Header">
            <StyledHeaderLogoLink to={Routes.LANDING_PAGE}>
                <MileagePlusLogo src={mileageplus_logo} alt={"MileagePlus Logo"}/>
            </StyledHeaderLogoLink>

        </StyledHeader>
    )
}

export default Header;