/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/Using-history-for-GA-Tracking
 * 
 * Added initLogPageView for the page that first visit (landed on)
 * https://github.com/react-ga/react-ga/issues/122#issuecomment-668640145
 */
import ReactGA from 'react-ga4';

export const initializeGA = () => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID as string);
};

export const logPageView = (history: any) => {
  history.listen((location: any) => {
    const page = location.pathname || window.location.pathname;
    ReactGA.set({ page: page });
    ReactGA.send({ hitType: "pageview", page: page })
  });
};

export const initLogPageView = (page: any) => {
    ReactGA.set({ page: page });
    ReactGA.send({ hitType: "pageview", page: page })
}