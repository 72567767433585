import React from 'react';
import styled from 'styled-components';

import ExternalButtonLink from './styling/ExternalButtonLink';


const Content = styled.div`
    max-width: 830px;
    margin: 0 auto;
    padding: 50px 20px 40px 20px;
`

export const Title = styled.h1`
    font-weight: ${props => props.theme.font.weight.semibold};
    font-size: 40px;
    margin-bottom: 20px;
`

const chaseCardsUri = "https://www.chase.com/personal/credit-cards/united";

export const IneligibleMember = () => {
    return (
        <Content>
            <Title>No United Chase credit cards found</Title>
            This program is only available for primary cardmembers of a United Chase credit card.
            <br/>
            <br/>
            If you are already a United credit cardmember and are receiving this error, please call United.com Customer Support at 1-800-589-5582.
            <br/>
            <br/>
            <br/>
            <ExternalButtonLink target={chaseCardsUri} text="Sign Up" />
            {/*<ButtonLink to={Routes.LANDING_PAGE} secondary={true}>MileagePlus Choices&reg; Homepage</ButtonLink>*/}
        
        </Content>
    )
}

export default IneligibleMember;