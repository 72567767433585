import * as ACTION_TYPES from "../actionTypes";
import parseError from './parseError.js';

export interface Transaction {
    choicesID: string,
	timestamp: string,
	program: string,
	dollarAmount: number,
	cardType: string,
	creditCardNumber: string,
	billingDate: string,
	transactionRefNumber: string,
	miles: number,
	status: string,
}

export interface Transactions {
    transactions: Array<Transaction>,
    error: string,
    isLoaded: boolean,
}

export const initialTransaction: Transaction = {
    choicesID: '',
    timestamp: '',
    program: '',
    dollarAmount: 0,
    cardType: '',
    creditCardNumber: '',
    billingDate: '',
    transactionRefNumber: '',
    miles: 0,
    status: '',
}

export const initialState: Transactions = {
    transactions: [],
    error: '',
    isLoaded: false,
}


export const transactions = (state: Transactions = initialState, action: any): Transactions => {
    switch (action.type) {
        case ACTION_TYPES.GET_TRANSACTIONS: {
            return initialState;
        }

        case ACTION_TYPES.GET_TRANSACTIONS_SUCCESS: {
            let error = parseError(action);

            if(!action?.payload?.data?.transactions) {
                return {
                    ...initialState,
                    error: 'Unable to parse transactions response',
                    isLoaded: true,
                }
            }

            if(error) {
                console.error('transactions failed - error: ' + error);
                return {
                    ...initialState,
                    error: error,
                    isLoaded: false,
                }
            }

            let txs: Array<Transaction> = [];

            action?.payload?.data?.transactions?.forEach((transaction: any) => {
                const tx: Transaction = {
                    choicesID: transaction.choicesID || '',
                    timestamp: transaction.timestamp || '',
                    program: transaction.program || '',
                    dollarAmount: transaction.centAmount/100 || 0, 
                    cardType: transaction.cardType || '',
                    creditCardNumber: transaction.creditCardNumber || '',
                    billingDate: transaction.billingDate || '', //"5/1/2020"
                    transactionRefNumber: transaction.transactionRefNumber || '',
                    miles: transaction.miles || 0,
                    status: transaction.status || '',
                }
                txs.push(tx);
            });
            
            return {
                transactions: txs,
                error: '',
                isLoaded: true,
            };
        }

        case ACTION_TYPES.GET_TRANSACTIONS_FAIL: {
            return {
                ...initialState,
                error: 'Transaction lookup failed',
                isLoaded: true,
            };
        }

        default: {
            return state
        }
    }
}

export default transactions