import parseError from './parseError.js';

export interface Error {
    redirectToLogin: boolean,
}

export const initialState: Error = {
    redirectToLogin: false,
}

let authCodeErrors = new Set();
authCodeErrors.add("Invalid online authenticationCode parameter")
    .add("Invalid offline authenticationCode parameter").add("Online authenticationCode parameter is expired")
    .add("Offline authenticationCode parameter is expired")

export const error = (state: Error = initialState, action: any): Error => {
    let error = parseError(action);

    //  Send to login if auth code is invalid or expired
    if(authCodeErrors.has(error)) {
        return {
            redirectToLogin: true,
        };
    }
    return initialState
}

export default error