import * as ACTION_TYPES from '../actionTypes';
import parseError from './parseError.js';


export interface AirfareRedeem {
    error: string,
    loading: boolean,
    reviewing: boolean,
    orderId: string,
    status: string,
    receiptSeen: boolean,
}

export const initialState: AirfareRedeem = {
    error: '',
    loading: false,
    reviewing: false,
    orderId: '',
    status: '',
    receiptSeen: false,
}

export const airfareRedeem = (state: AirfareRedeem = initialState, action: any): AirfareRedeem => {
    switch(action.type){
        case ACTION_TYPES.SAVE_AIRFARE_REDEEM_FORM_DATA: {
            return {
                ...initialState,
                reviewing: true,
                orderId: '', //There shouldn't be any orderId at the start of the redeem flow
            }
        }

        case ACTION_TYPES.STOP_REVIEWING: {
            return {
                ...initialState,
                error: '', //Clear errors when returning to form
                reviewing: false,
            }
        }

        case ACTION_TYPES.AIRFARE_REDEEM: {
            return {
                ...initialState,
                loading: true,
                reviewing: true,
            };
        }
        case ACTION_TYPES.AIRFARE_REDEEM_SUCCESS: {
            let error = parseError(action);

            if(!action?.payload?.data?.choicesID || !Number.isInteger(Number(action.payload.data.choicesID)) || Number(action.payload.data.choicesID) < 1){
                error = 'Failed to parse airfareRedeem response';
            }

            if(error) {
                console.error('airfareRedeem failed - error: ' + error);
                return {
                    ...initialState,
                    error: error,
                    loading: false,
                    reviewing: false,
                }
            }

            return {
                error: '',
                loading: false,
                reviewing: false,
                orderId: action.payload.data.choicesID,
                status: action.payload.data.status, //this is the http response code, not the tx status
                receiptSeen: false,
            };
        }
        case ACTION_TYPES.AIRFARE_REDEEM_FAIL: {
            const error: string = parseError(action) || 'Error redeeming airfare transaction';
            console.error('airfareRedeem failed - error: ' + error);
            return {
                ...initialState,
                error: error,
                loading: false,
                reviewing: false,
            };
        }
        default: {
            return state;
        }
    }
}


export default airfareRedeem;