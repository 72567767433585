import React, { useLayoutEffect } from 'react'
import styled from 'styled-components'

import background from './images/background.png'
import bannerAd from './images/landingPageBannerAd.png'
import bannerAd_small from './images/landingPageBannerAd_small.png'
import ButtonLink from './styling/ButtonLink';
import { useLocation } from 'react-router-dom';

const Body = styled.div`
`

export const TitleImg = styled.img`
    height: 300px;
    width: 100%;
    margin-bottom: -280px;
    object-fit: cover;

    @media(min-width:${props => props.theme.minWidth.giga}) {
        height: 450px;
        margin-bottom: -430px;
    }
`

const Content = styled.div`
    max-width: ${props => props.theme.minWidth.giga};
    margin: 0 auto;
    padding: 0 20px;
`

const TitleDiv = styled.div`
    max-width: 475px;
    padding: 50px 0 50px 0;
    color: white;
    text-shadow: 1px 1px 2px #000;

    @media(min-width:${props => props.theme.minWidth.giga}) {
        padding-top: 108px;
        padding-bottom: 130px;
    }
`

const RedeemLinksDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @media(min-width:${props => props.theme.minWidth.giga}) {
        flex-direction: row;
    }
`

const RedeemLinkDiv = styled.div`
    width: 100%;
    margin-bottom: 30px;    
    background: #FFFFFF;
    padding: 30px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;

    @media(min-width:${props => props.theme.minWidth.giga}) {
        height: 225px;
    }
`

export const RedeemLinkIcon = styled.img`
    margin-bottom: 20px;
`

export const H3 = styled.h3`
    margin-bottom: 10px;
`

export const RedeemLinkP = styled.p`
    @media(min-width:${props => props.theme.minWidth.giga}) {
        height: 66px;
    }
`

export const ChoicesActivityButtonLink = styled(ButtonLink)`
    height: 50px;
    width: 100%;
    background-color: #FFFFFF;
    border: 2px solid #6244BB;

    color: #6244BB;

    @media(min-width:${props => props.theme.minWidth.giga}) {
        width: 275px;
    }
`

const StyledList = styled.ul`
  list-style-type: disc;
  padding-left: 1em;
  margin-left: 1em;
  margin-bottom: 1em;
`

const StyledListItem = styled.li`
`

const StyledAnnualFeeBannerStepParagraph = styled.p`
    font-size: 0.8em;
    font-style: italic;
`

const FootnoteDiv = styled.div`
    font-size: 10px;
    margin-bottom: 1em;
`

interface ResponsiveSwitchImageProps {
    theme: any,
    bigImgUrl: string,
    smallImgUrl: string,
    alt: string,
}

export const ResponsiveSwitchImage = styled.div`
    margin: 0 -20px 20px -20px;
    width: calc(100% + 40px);
    background-image: url(${(props: ResponsiveSwitchImageProps) => props.smallImgUrl});
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;

    @media (min-width: ${(props: ResponsiveSwitchImageProps) => props.theme.minWidth.giga}) {
        background-image: url(${(props: ResponsiveSwitchImageProps) => props.bigImgUrl});
        margin: 0;
        width: 100%;
    }
`

const ResponsiveSwitchImageInternal = styled.img`
    visibility: hidden;
    width: 100%;
`

export const LandingPage = () => {

    const { pathname } = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname]);

    return (
        <Body>
        
        <TitleImg src={background} />
        <Content>
            <TitleDiv>
                <h1>We've moved!</h1>
                <h1>Use miles to pay your annual fee and United airfare via <br />Pay Yourself Back</h1>
            </TitleDiv>
            
            {/* Redemption links */}
            <RedeemLinksDiv>
                <RedeemLinkDiv>
                
                    <StyledList>
                        <StyledListItem>Sign into your account <a href="https://chaseloyalty.chase.com/pay-yourself-back">here</a></StyledListItem>
                        <StyledListItem>Click on "More" on the top right corner of your account page</StyledListItem>
                        <StyledListItem>Select "Rewards dashboard" from the menu</StyledListItem>
                        <StyledListItem>Choose "Use MileagePlus miles with Pay Yourself Back" on the top navigation bar</StyledListItem>
                    </StyledList>
                    <StyledAnnualFeeBannerStepParagraph>If you are a United Awards Cardmember and want to redeem miles for your annual fee, please call the number on the back of your credit card to reach a Chase specialist who can process your transaction.</StyledAnnualFeeBannerStepParagraph>
                    {/* <RedeemLinkIcon src={airfareRedeemIcon} alt="airfare redemption"/>
                    <H3>Use miles to pay for your airfare credit</H3>
                    <RedeemLinkP>Use miles earned with your card for an airfare statement credit on United tickets purchased with your card at united.com</RedeemLinkP>
                    <ButtonLink to={Routes.AIRFARE}>Redeem airfare</ButtonLink> */}
                </RedeemLinkDiv>
            </RedeemLinksDiv>

            <a
                    href="https://www.chase.com/referafriend/unitedmileageplusfamily"
                    target="_blank"
            >
                <ResponsiveSwitchImage bigImgUrl={bannerAd} smallImgUrl={bannerAd_small} alt="bonus offer">
                    <ResponsiveSwitchImageInternal src={bannerAd_small}/>
                </ResponsiveSwitchImage>
            </a>

            <FootnoteDiv>
                * Use MileagePlus&reg; miles with Pay Yourself Back&reg;: Miles may be redeemed for a statement credit using Pay Yourself Back against the prior payment of your annual fee within 90 days before the redemption request date. Redemption values will be shown on the transaction page prior to redemption. Redemption values may be changed at any time. Statement credits will post to the card account within 3 business days of a request to redeem and will appear on the monthly Chase credit card billing statement within 1-2 billing cycles. Statement credits will reduce your balance but you are still required to make at least your minimum monthly payment. Chase reserves the right to determine which purchases qualify for a statement credit.
            </FootnoteDiv>
        </Content>

        </Body>
    )
}

export default LandingPage