import React from 'react'
import styled from 'styled-components'
// import OpenInNew from '@material-ui/icons/OpenInNew'

import Button from './Button';


const A = styled.a`
  width: 100%;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`

// const Button = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #6244bb;
//   color: white;
//   width: ${props => (props.width ? props.width : '100%')}
//   padding: 20px 20px;
//   border: solid;
//   border-width: 2px;
//   border-color: #755bc4;
//   border-radius: 5px;
//   font-size: 16px;

//   @media (min-width: ${props => props.theme.minWidth.giga}) {
//     width: ${props => (props.width ? props.width : '285px')};
//   }
// `

interface Props {
    target: string,
    text: string,
    openInNew?: boolean,
}

export const ExternalButtonLink = ({target, text, openInNew}: Props) => {
    return (
        <A href={target}>
        <Button>
            {text} &nbsp;
            {/* {openInNew && <OpenInNew />} */}
        </Button>
        </A>
    )
}

export default ExternalButtonLink
