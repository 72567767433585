import React from 'react';
import { Link } from 'react-router-dom';
import * as Routes from './Routes';
import styled from 'styled-components';

const StyledFooter = styled.div`
    position: absolute;
    bottom: 0;    
    width:100%;
    background: #0D243F 0% 0% no-repeat padding-box;
    opacity: 1;
    height: ${props => props.theme.footer.maxHeight};

    @media (min-width: ${props => props.theme.minWidth.giga}) {
        height: ${props => props.theme.footer.minHeight};
    }
`

const StyledList = styled.ul`
    padding: 35px 0px 0px 0px;
    overflow: hidden;
    text-align: center;

    @media (min-width: ${props => props.theme.minWidth.giga}) {
        padding: 21px 0px 0px 0px;
        display: flex;
        justify-content: space-around;
    }
`

const StyledListItem = styled.li`
    font-family: "Open Sans", sans-serif;
    font-size: 16px; 
    font-style: normal;

    & > a {
        display: block;
        color: #ffffff;
        text-decoration: none;
        padding: 0px 0px 26px 0px;
    }

    @media (min-width: ${props => props.theme.minWidth.giga}) {
        border: none;

        & > a {
            display: block;
            color: #ffffff;
            text-decoration: none;
        }
    }
`

export const StyledLink = styled(Link)`
    color: #ffffff;
    text-decoration: none;
`

export const Footer = (props: any) => {
    //TODO: style each link based on location.pathname === <linkPath> to highlight current page

    return (
        <StyledFooter id="Footer">
            <StyledList>
                <StyledListItem>
                    <StyledLink to={Routes.LANDING_PAGE}>About the MileagePlus Choices program</StyledLink>
                </StyledListItem>
                {/*<StyledListItem>
                    <StyledLink to={Routes.LANDING_PAGE}>FAQs</StyledLink>
                </StyledListItem>*/}
                <StyledListItem>
                    <StyledLink to={Routes.TERMS}>Terms and conditions</StyledLink>
                </StyledListItem>
                <StyledListItem>
                    <a
                    href="https://www.united.com/ual/en/us/fly/mileageplus/rules.html"
                    target="_blank"
                    >
                    MileagePlus program rules
                    </a>
                </StyledListItem>
                <StyledListItem>
                    <a
                    href="https://www.united.com/ual/en/us/fly/privacy.html"
                    target="_blank"
                    >
                    United privacy policy
                    </a>
                </StyledListItem>
            </StyledList>
        </StyledFooter>
    )
}

export default Footer;