import { combineReducers } from 'redux';
import authCode from './authCode';
import profile from './profile';
import airfareRedeemForm from './airfareRedeemForm';
import airfareRedeem from './airfareRedeem';
import transactions from './transactions';
import error from './error';

const rootReducer = combineReducers({
    authCode,
    profile,
    airfareRedeem,
    airfareRedeemForm,
    transactions,
    error,
});

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer