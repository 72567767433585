import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

import { createGlobalStyle, ThemeProvider } from 'styled-components';

import { initializeGA } from './analytics/withTracker';

function AppWrappers() {

    // Global styles
    // eslint-disable-next-line
    const GlobalStyle = createGlobalStyle`
        html {
            height: 100%;
            width: 100%;
            margin:0;
        }
        body {
            height: 100%;
            width: 100%;
            margin: 0;
            padding: 0;
            font-family: 'Open Sans';
            font-size: 16px;
            color: #0D243F;
        }
        #root {
            min-height:100%;
            position:relative;
        }
        *{
            box-sizing: border-box;
            margin: 0;
            padding: 0;
        }
    `

    const theme = {
        font: {
            default: 'Open Sans',
            // jp: "'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', 'ＭＳ ゴシック', 'MS Gothic', sans-serif",

            weight: {
                regular: '400',
                semibold: '600',
            },
        },
        minWidth: {
            giga: '1024px',
            hardMin: '300px', //don't allow the width to get any smaller than this
        },
        footer: {
            minHeight: '99px',
            maxHeight: '279.5px',
        },
    }

    initializeGA()

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <GlobalStyle/>
                    <App/>
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    )
}

export default AppWrappers;