import queryString from 'query-string'

import * as ACTION_TYPES from '../actionTypes';
import * as Routes from '../../Routes';


export interface AuthCode {
    onlineAuthCode: string,
}

export const initialState: AuthCode = {
    onlineAuthCode: '',
}

export const authCode = (state: AuthCode = initialState, action: any): AuthCode => {
    switch(action.type) {
        case ACTION_TYPES.GET_AUTH_CODE: {
            const urlQuery = action?.location?.search;
            const queryParams = queryString.parse(urlQuery);
            const urlOnlineAuthCode: string = (
                typeof queryParams['onlineAuthCode'] === 'string' ? queryParams['onlineAuthCode'] :
                (Array.isArray(queryParams['onlineAuthCode']) && queryParams['onlineAuthCode'].length > 0) ? queryParams['onlineAuthCode'][0]:
                '');

            const sessionStorageAuthCode = sessionStorage.getItem('onlineAuthCode');

            //Prefer new auth code from the url but return old auth code from the store if there is no new one
            const onlineAuthCode = urlOnlineAuthCode?urlOnlineAuthCode:
                state.onlineAuthCode?state.onlineAuthCode:
                sessionStorageAuthCode?sessionStorageAuthCode:
                '';

            // Return to preLoginPage after login
            const preLoginPage = sessionStorage.getItem('preLoginPage');
            if (onlineAuthCode && preLoginPage) {
                window.location.assign(preLoginPage);
                sessionStorage.removeItem('preLoginPage');
            }
            
             //Send to login if there's no auth code
            if(!onlineAuthCode && action?.location?.pathname !== Routes.LANDING_PAGE && action?.location?.pathname !== Routes.TERMS) {
                //Store the current page so we can come back to it after login
                sessionStorage.setItem('preLoginPage', action.location.pathname);
                
                const loginUrl = process.env.REACT_APP_LOGIN_URL + '?CID=' + process.env.REACT_APP_LOGIN_CID;
                window.location.assign(loginUrl); //TODO: browserHistory.push instead of window.location.assign?
            }

            sessionStorage.setItem('onlineAuthCode', onlineAuthCode);
            
            return {
                onlineAuthCode: onlineAuthCode,
            }
        }
        case ACTION_TYPES.LOGIN: {
            sessionStorage.removeItem('onlineAuthCode');

            //Send the user to the login page
            const loginUrl = process.env.REACT_APP_LOGIN_URL + '?CID=' + process.env.REACT_APP_LOGIN_CID;
            window.location.assign(loginUrl);
            
            return {
                onlineAuthCode: '',
            }
        }
        case ACTION_TYPES.LOGOUT: {
            sessionStorage.removeItem('onlineAuthCode');

            //Send the user to the logout page
            const logoutUrl = process.env.REACT_APP_LOGOUT_URL + '?CID=' + process.env.REACT_APP_LOGIN_CID;
            window.location.assign(logoutUrl);
            
            return {
                onlineAuthCode: '',
            }
        }
        default: {
            //check for 401 unauthorized (unauthenticated) status
            //or 403? (when does the function/authorizer return a 401 and when does it return a 403?)
            if(action?.error?.response?.status === 401 || action?.error?.response?.status === 403) {
                //Send the user back to the login page
                const loginUrl = process.env.REACT_APP_LOGIN_URL + '?CID=' + process.env.REACT_APP_LOGIN_CID;
                window.location.assign(loginUrl);

                //remove onlineAuthCode
                return {
                    onlineAuthCode: '',
                }
            }


            return state;
        }
    }
}

export default authCode;