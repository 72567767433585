import * as ACTION_TYPES from "../actionTypes";
import parseError from './parseError.js';

export interface UnmappedPartnerCard {
    cardType: string,
    cardTypeDescription: string,
    accountCode: string, //creditCardNumber
    annualFee: number,
    AF_BillingDate: string, //aF_BillingDate
    redemptionDollarsPerMile: number,
}

export const emptyUnmappedPartnerCard: UnmappedPartnerCard = {
    cardType: '',
    cardTypeDescription: '',
    accountCode: '',
    annualFee: 0,
    AF_BillingDate: '',
    redemptionDollarsPerMile: 0,
}

export interface PartnerCard {
    cardType: string,
    cardTypeDescription: string,
    creditCardNumber: string, //accountCode
    annualFee: number,
    billingDate: string, //aF_BillingDate
    dollarsPerMile: number,
}

export const emptyCard: PartnerCard = {
    cardType: '',
    cardTypeDescription: '',
    creditCardNumber: '',
    annualFee: 0,
    billingDate: '',
    dollarsPerMile: 0,
}

export interface Profile {
    error: string,
    isLoaded: boolean,
    memberId: string,
    firstName: string,
    lastName: string,
    email: string,
    balance: number,
    choiceMilesBalance: number,
    partnerCards: Array<PartnerCard>,
    airfareDollarsPerMile: number,
    isEligible: boolean,
}

export const initialState: Profile = {
    error: '',
    isLoaded: false,
    memberId: '',
    firstName: '',
    lastName: '',
    email: '',
    balance: 0,
    choiceMilesBalance: 0,
    partnerCards: [],
    airfareDollarsPerMile: 0,
    isEligible: true,
}

//TODO: is typing the action param to getProfileAction wrong? Do all actions (even non getPRofile actions) need to go through all reducers?
export const profile = (state: Profile = initialState, action: any): Profile => {
  switch (action.type) {
    case ACTION_TYPES.GET_PROFILE: {
        return initialState;
    }

    /*
    {
        "memberID": "AX268203",
        "profileData": {
            "emailAddress": "test@united.com",
            "newBalance": 9064,
            "firstName": "test",
            "lastName": "jeste",
            "memberLevel": 4,
            "choiceMilesBalance": 0,
            "partnerCards": [
                {
                    "partnerCode": "CH",
                    "cardType": "0480",
                    "cardTypeDescription": "United Explorer Card",
                    "productType": "",
                    "AF_BillingDate": "2019-09-05T04:40:08",
                    "accountCode": "************1611",
                    "annualFee": 123,
                    "redemptionDollarsPerMile": 1
                }
            ]
        },
        "redemptionRate": {
            "annualFee": {
                "dollarsPerMile": 0.015
            },
            "airfare": {
                "dollarsPerMile": 1
            }
        },
        "exception": null
    }
    */
    case ACTION_TYPES.GET_PROFILE_SUCCESS: {
        if(action?.payload?.data?.exception?.exceptionMessage){
            return {
                ...initialState,
                error: action.payload.data.exception.exceptionMessage,
                isLoaded: true,
            }
        }
                
        if(!action?.payload?.data?.profileData){
            return {
                ...initialState,
                error: 'Unable to parse profile from getProfile response',
                isLoaded: true,
            };
        }

        const data = action.payload.data.profileData;

        if(!Array.isArray(data.partnerCards) || data.partnerCards.length < 1) {
            return {
                ...initialState,
                error: 'This program is only available for primary cardmembers of a United Chase credit card. If you are already a United credit cardmember and are receiving this error, please call United.com Customer Support at 1-800-589-5582.',
                isLoaded: true,
                isEligible: false,
            }
        }

        let cards: Array<PartnerCard> = [];

        data.partnerCards.forEach((card: UnmappedPartnerCard) => {
            var newCard = {...emptyCard};
            
            newCard.cardType = card.cardType;
            newCard.cardTypeDescription = card.cardTypeDescription;
            newCard.creditCardNumber = card.accountCode;
            newCard.annualFee = card.annualFee;
            newCard.billingDate = card.AF_BillingDate; //'2020-08-01T00:00:00';
            newCard.dollarsPerMile = card.redemptionDollarsPerMile;
            
            cards.push(newCard);
        })

        const balance = data.newBalance || 0;
        const choicesMilesBalance = Math.min(data.choiceMilesBalance, balance);

        return {
            error: '',
            isLoaded: true,
            memberId: action.payload.data.memberID || '',
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            email: data.emailAddress || '',
            balance: balance,
            choiceMilesBalance: choicesMilesBalance,
            partnerCards: cards || [],
            airfareDollarsPerMile: action?.payload?.data?.redemptionRate?.airfare?.dollarsPerMile || 0,
            isEligible: true,
        };
    }
    case ACTION_TYPES.GET_PROFILE_FAIL: {
        let error = parseError(action);

        console.error("getProfile failed - error: " + error);
        
        return {
            ...initialState,
            error: error,
            isLoaded: true,
        };
    }

    //Clear profile on logout
    case ACTION_TYPES.LOGOUT:{
        return initialState;
    }

    case ACTION_TYPES.AIRFARE_REDEEM_SUCCESS: {
        let error = parseError(action);
        
        if (!error) {
            //update the profile balance after a successful redemption
            return {
                ...state,
                balance: action?.payload?.data?.balance,
                choiceMilesBalance: action?.payload?.data?.choicesBalance,
            }
        }
        return state;
    }

    default: {
        return state;
    }
  }
};

export default profile;
