import React from 'react'
import styled from 'styled-components'
import PulseLoader from 'react-spinners/PulseLoader';


interface Props {
    type?: 'button' | 'submit' | 'reset' | undefined,
    onClickProp?: any,
    disabled?: boolean,
    loading?: boolean,
    secondary?: boolean,
    className?: string,
    children?: any,
}

const Button = ({type = 'button', onClickProp, disabled, loading = false, secondary, className, children}: Props) => {
    // const { disabled, loading, className, children, ...others } = this.props;
    const inactive = disabled || loading;

    if(onClickProp) {
        return(
            <button type={type} onClick={onClickProp} className={className} disabled={inactive} >
                <PulseLoader loading={loading} color={secondary?'#9386b9':'#FFFFFF'}/>
                {!loading && <span>{children}</span>}
            </button>
        )
    } else {
        return(
            <button type={type} className={className} disabled={inactive} >
                <PulseLoader loading={loading} color={secondary?'#9386b9':'#FFFFFF'}/>
                {!loading && <span>{children}</span>}
            </button>
        )
    }
}

interface StyledButtonProps {
    theme: any,
    disabled?: boolean,
    loading?: boolean,
    secondary?: boolean,
}

const StyledButton = styled(Button)`
    height: 50px;
    width: 100%;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    vertical-align: top;

    @media (min-width: ${props => props.theme.minWidth.giga}) {
        width: 250px;
        margin-right: 20px;
    }

    ${(props:StyledButtonProps) => {
        if(props.disabled || props.loading) {

            if(props.secondary) {
                return `
                    background-color: #FFFFFF;
                    border: 1px solid #9386b9;
                    color: #9386b9;
                `
            } else {
                return `
                    background-color: #9386b9;
                    color: #FFFFFF;
                `
            }
        } else {
        
            if(props.secondary) {
                return `
                    background-color: #FFFFFF;
                    border: 1px solid #6244BB;
                    color: #6244BB;
                `
            } else {
                //primary styling
                return `
                    background-color: #6244BB;
                    color: #FFFFFF;
                `
            }
        }
    }}
`;

export default StyledButton