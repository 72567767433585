import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Dispatch } from 'redux';
import { useLocation } from 'react-router-dom'

import { getAuthCode, getProfile } from './redux/actions';
import { RootState } from './redux/reducers/rootReducer';
import { AuthCode } from './redux/reducers/authCode';
import { Error } from './redux/reducers/error';
import { Profile } from './redux/reducers/profile';

import * as Routes from './Routes';
import Header from './Header';
import Footer from './Footer';
import LandingPage from './LandingPage';
import Airfare from './Airfare';
import ActivitySummary from './ActivitySummary';
import AirfareReceipt from './AirfareReceipt';
import Terms from './Terms';
import IneligibleMember from './IneligibleMember';

import { logPageView, initLogPageView } from './analytics/withTracker';

import styled from 'styled-components';

const StyledBody = styled.div`
    padding-bottom: ${props => props.theme.footer.maxHeight};

    @media (min-width: ${props => props.theme.minWidth.giga}) {
        padding-bottom: ${props => props.theme.footer.minHeight};
    }

    @media print {
        visibility: hidden;
    }
`

function App() {
    const [loggedInitialPageView, setLoggedInitialPageView] = useState(false);
    const authCode: AuthCode = useSelector((state: RootState) => state.authCode);
    const onlineAuthCode: string = authCode?.onlineAuthCode;
    const error: Error = useSelector((state: RootState) => state.error);
    const profile: Profile = useSelector((state: RootState) => state.profile);
    const dispatch: Dispatch = useDispatch();

    //Read online auth code from the url query params
    //(reads from store then sessionStorage if there's nothing in the url)
    //(redirects to crew-login if there is no auth code unless it's on the landing page)

    const location = useLocation();
    //we could use urlQuery as a dependency instead of location, but then we'd have to do the redirect to login on each page except landing
    //const urlQuery = location.search;

    //Send to login if auth code is invalid or expired
    if(error.redirectToLogin === true && location?.pathname !== Routes.LANDING_PAGE && location?.pathname !== Routes.TERMS) {
        //Store the current page so we can come back to it after login
        sessionStorage.setItem('preLoginPage', location.pathname);

        const loginUrl = process.env.REACT_APP_LOGIN_URL + '?CID=' + process.env.REACT_APP_LOGIN_CID;
        window.location.assign(loginUrl); //TODO: browserHistory.push instead of window.location.assign?
    }

    useEffect(() => {
        dispatch(getAuthCode(location))
    }, [dispatch, location])
    
    //Get profile as soon as we have an auth code
    useEffect(() => {
        if(onlineAuthCode){
            dispatch(getProfile(onlineAuthCode));
        }
    }, [onlineAuthCode, dispatch]);

    const history = useHistory();
    useEffect(() => {
        logPageView(history);
    }, [history]);

    useEffect(() => {
        if (!loggedInitialPageView) {
            initLogPageView(location.pathname + location.search); 
            setLoggedInitialPageView(true)
        }
    }, [loggedInitialPageView, location]);

    if(profile?.isEligible) {
        return (
            <StyledBody>
                <Header />

                <Switch>
                    
                    <Route path={Routes.TERMS} component={Terms}><Terms /></Route>
                    <Route path={Routes.LANDING_PAGE} component={LandingPage}><LandingPage /></Route>
                </Switch>

                <Footer />
            </StyledBody>
        );
    } else {
        return (
            <StyledBody>
                <Header />

                <IneligibleMember />

                <Footer />
            </StyledBody>
        );
    }
}

export default App;
